<template>
  <v-navigation-drawer
    class="ease-bar"
    app
    :style="backgroundStyle"
    :height="height"
  >
    <v-list-item class="px-0">
      <v-list-item-content class="py-0" style="height: 48px;">
        <TranslationMenu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" dark text tile large height="100%" v-on="on">
              <v-icon>
                mdi-translate
              </v-icon>
            </v-btn>
          </template>
        </TranslationMenu>
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="px-0 mb-12">
      <v-list-item-content class="py-0" style="height: 48px;">
        <UserAvatar v-if="userName">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" dark text tile large height="100%" v-on="on">
              <v-icon left>
                mdi-account-circle
              </v-icon>
              {{ userName }}
            </v-btn>
          </template>
        </UserAvatar>
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="mt-12">
      <v-list-item-content>
        <div v-for="action in actions" :key="`span-${action.text}`">
          <v-btn
            v-if="action.attr"
            :key="`btn-${action.text}`"
            :color="action.color"
            text
            x-large
            @click="$emit('attr-change', action)"
          >
            <v-icon v-if="action.icon" left>
              {{ action.icon }}
            </v-icon>
            {{ action.text }}
          </v-btn>
          <v-btn
            v-else-if="action.to"
            :key="`btn-${action.text}`"
            :color="action.color"
            text
            x-large
            width="100%"
            :to="action.to"
          >
            <v-icon v-if="action.icon" large>
              {{ action.icon }}
            </v-icon>
          <!-- {{ action.text }} -->
          </v-btn>
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-spacer />

    <v-toolbar-title
      class="mx-11 d-flex align-center"
      style=" min-width: 40px;"
    >
      <v-btn color="primary" style="width: 100%;" rounded @click="toHomePage">
        <v-icon left>
          mdi-home-variant-outline
        </v-icon>
        {{ $t('back_to_home') }}
      </v-btn>
      <!-- <v-img
        contain
        max-height="114%"
        src="@/assets/images/logo.png"
        style="cursor: pointer"
        @click="toHomePage"
      /> -->
    </v-toolbar-title>
    <!-- <slot name="logo" :toHomePage="toHomePage" :attrs="{style:'cursor: pointer'}" /> -->
  </v-navigation-drawer>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'
import TranslationMenu from '@/components/TranslationMenu'

import { mapGetters } from 'vuex'

export default {
  components: {
    UserAvatar,
    TranslationMenu
  },

  props: {
    actions: {
      type: Array,
      default: () => []
    },
    backgroundStyle: {
      type: Object,
      default: () => {}
    },
    height: {
      type: [Number, String],
      default: undefined
    }
  },

  computed: {
    ...mapGetters({
      userName: 'user/userName'
    })
  },

  created() {
    if (!this.userName) {
      this.fetchUser()
    }
  },

  methods: {
    fetchUser() {
      this.$store.dispatch('user/fetchUser')
    },
    toHomePage() {
      this.$router.push({ name: 'Projects' }).catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.ease-bar {
  ::v-deep .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    .v-list-item {
      flex: 0 0;
    }
  }
}
</style>
